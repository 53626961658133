import React from 'react';
import profile from '../static_profile_square2.png';

const Title: React.FC = () => {
  return (
    <div>
      <img className="profile-pic" src={profile} />
      <h1>
        <b>T</b>suki<b>N</b>o<b>N</b>eko
      </h1>
    </div>
  );
};

export default Title;
